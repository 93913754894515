// @font-face {
//     font-family: 'Rhetorik Sans';
//     src: url('../assets/fonts/RhetorikSans/RhetorikSans-Bold.otf') format('opentype');
//     font-weight: 700;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Rhetorik Sans';
//     src: url('../assets/fonts/RhetorikSans/RhetorikSans-BoldItalic.otf') format('opentype');
//     font-weight: 700;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Rhetorik Sans';
//     src: url('../assets/fonts/RhetorikSans/RhetorikSans-Italic.otf') format('opentype');
//     font-weight: 400;
//     font-style: italic;
//     font-display: swap
// }

// @font-face {
//     font-family: 'Rhetorik Sans';
//     src: url('../assets/fonts/RhetorikSans/RhetorikSans-Light.otf') format('opentype');
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Rhetorik Sans';
//     src: url('../assets/fonts/RhetorikSans/RhetorikSans-LightItalic.otf') format('opentype');
//     font-weight: 300;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Rhetorik Sans';
//     src: url('../assets/fonts/RhetorikSans/RhetorikSans-Medium.otf') format('opentype');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap
// }

// @font-face {
//     font-family: 'Rhetorik Sans';
//     src: url('../assets/fonts/RhetorikSans/RhetorikSans-MediumItalic.otf') format('opentype');
//     font-weight: 500;
//     font-style: italic;
//     font-display: swap
// }

// @font-face {
//     font-family: 'Rhetorik Sans';
//     src: url('../assets/fonts/RhetorikSans/RhetorikSans-Regular.otf') format('opentype');
//     font-weight: 400;
//     font-style: normal;
//     font-display: swap
// }

// @font-face {
//     font-family: 'Rhetorik Sans';
//     src: url('../assets/fonts/RhetorikSans/RhetorikSans-Thin.otf') format('opentype');
//     font-weight: 100;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Rhetorik Sans';
//     src: url('../assets/fonts/RhetorikSans/RhetorikSans-ThinItalic.otf') format('opentype');
//     font-weight: 100;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Fakt';
//     src: url('../assets/fonts/Fakt/fakt-nor.ttf');
//     font-weight: 400;
//     font-style: normal;
//     font-display: swap
// }

// @font-face {
//     font-family: 'Fakt';
//     src: url('../assets/fonts/Fakt/fakt-med.ttf');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap
// }

// @font-face {
//     font-family: 'Fakt';
//     src: url('../assets/fonts/Fakt/fakt-bd.ttf');
//     font-weight: 700;
//     font-style: normal;
//     font-display: swap
// }

// @font-face {
//     font-family: 'Fakt';
//     src: url('../assets/fonts/Fakt/fakt-smbd.ttf');
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap
// }

// .fakt {
//     font-family: 'Fakt', sans-serif;
// }

@font-face {
    font-family: 'The Future';
    src: url('../assets/fonts/TheFuture/the-future-regular.woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'The Future';
    src: url('../assets/fonts/TheFuture/the-future-medium.woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap
}


@font-face {
    font-family: 'The Future';
    src: url('../assets/fonts/TheFuture/the-future-bold.woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'The Future';
    src: url('../assets/fonts/TheFuture/the-future-black.woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap
}
