.modal-lg-680 {

    @media (min-width: 992px) {
        .modal-dialog {
            max-width: 680px;
            width: 680px;
        }
    }

}

.modal-border-40 {
    .modal-dialog {
        border-radius: 40px;
    }
}

//fiction-facts campaign
.ff-modal-dialog {

    @media (min-width: 992px) {
        max-width: 600px !important;
        width: 600px !important;
    }

    .modal-content {
        border-radius: 40px;
    }
}

.signup-modal-dialog {
    @media (min-width: 992px) {
        max-width: 500px !important;
        width: 500px !important;
    }

    .modal-content {
        border-radius: 40px;
        height: 472px;

        .modal-header {
            display: none;

            .btn-close {
                // margin-right: 5px !important;
                display: none !important;
            }
        }

        .modal-body {
            padding-top: 0;
        }
    }
}

.verify-modal-dialog {
    @media (min-width: 992px) {
        max-width: 500px !important;
        width: 500px !important;
    }

    .modal-content {
        border-radius: 40px;
        height: 342px;

        .modal-header {
            display: none;

            .btn-close {
                // margin-right: 5px !important;
                display: none !important;
            }
        }

        .modal-body {
            padding-top: 0;
        }
    }
}

.modal-lg {
    .modal-content {
        .modal-header {
            --bs-modal-header-padding: 1.5rem;
        }

        .modal-body {
            --bs-modal-padding: 1.5rem;
        }
    }
}

.modal-fullscreen {
    .modal-content {
        .modal-header {
            --bs-modal-header-padding: 1rem;
        }

        .modal-body {
            --bs-modal-padding: 1rem;
        }
    }
}