.reader-modal-body {
    overflow-x: hidden;
}

.reader-modal-header {
    max-height: 72px;

    div {
        .reader-modal-title {
            .black {
                color: #121212;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.14px;
            }

            .grey {
                color: #414141;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.14px;
            }

            .truncate {
                width: calc(0.45 * var(--bs-modal-width));
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.reader-modal-header.bonus-scene-mode {
    background: #EDB56B;
}

.font-change-icon {
    color: #414141;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    user-select: none;
}

.reader-outer-container {
    width: 100%;
    height: 100%;

    .reader-inner-container {
        display: inline-block;
        position: relative;
    }

    .reader-sidebar {
        position: absolute;
        background-color: #fff;
        top: calc(var(--scroll-top) - 32px); //32px is the padding from header
        right: -40px; //x padding

        @media (max-width: 768px) {
            right: -20px;
        }

        width: 200px;
        height: calc(100vh + 32px - var(--bs-modal-margin) * 2 - var(--header-height));
        overflow-y: auto;
        box-shadow: 0px 8px 40px 6px rgba(0, 0, 0, 0.04),
        0px 0px 2px 0px rgba(0, 0, 0, 0.15),
        0px 2px 5px 0px rgba(0, 0, 0, 0.05);

        .sidebar-content {
            position: sticky;
            top: 0;
        }
    }
}

.sidebar-open {
    animation-duration: 1s;
    animation-name: slidein;
}

.sidebar-close {
    animation-duration: 1s;
    animation-name: slideout;
}

@keyframes slidein {
    from {
        right: -240px;

        @media (max-width: 768px) {
            right: -220px;
        }
    }

    to {
        right: -40px;

        @media (max-width: 768px) {
            right: -20px;
        }
    }
}

@keyframes slideout {
    from {
        right: -40px;

        @media (max-width: 768px) {
            right: -20px;
        }
    }

    to {
        right: -220px;

        @media (max-width: 768px) {
            right: -240px;
        }
    }
}

.cover-effect {
    height: 100px;
    display: inline-block;
    position: relative;
    margin-right: 75px;

    .background {
        position: absolute;
        left: 8px;
        top: -10px;
        transform: rotate(10deg);
        display: inline-block;
        width: 65px;
        height: 100px;
        background-color: #FFF3E0;
        border-radius: 4px;
        border: 1px solid #FFF;
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        transform: rotate(-4deg);
        border-radius: 4px;
        border: 1px solid #FFF;
        height: 100px;
    }
}

.extras-badge {
    display: inline-flex;
    width: fit-content;
    height: 23px;
    padding: 0px 8px;
    align-items: center;
    gap: 2px;
    flex-shrink: 0;
    border-radius: 100px;
    border: 1px solid #FF9800;
    background: #FFF3E0;

    .text {
        color: #FF9800;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: -2px;
    }

    .icon {
        svg {
            margin-top: -6px;
        }
    }
}

.extras-badge.white-bg {
    background: #FFF3E0;
}

.bonus-scene-title {
    color: #121212;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.bonus-scene-cover {
    height: 100px;
    border-radius: 8px;
}

.bonus-scene-unlock-title {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
}

.bonus-scene-unlock-form {
    width: 400px;

    @media(max-width: 768px) {
        width: 100%;
    }

    label {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.btn-custom {
    height: 48px;
    font-size: 16px;

    color: #000;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (max-width: 768px) {
        height: 56px;
        font-size: 1.125rem;
    }
}

.input-custom {
    height: 48px;

    @media (max-width: 768px) {
        height: 56px;
    }
}

.custom-hr {
    width: 200vw;
    margin-left: -50%;
    border-top: 1px solid #D9D9D9;
    height: 1px;
    display: block;
}

.orange {
    color: #FF9100;
}

.bonus-scene-unlock-toast {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    border-radius: 8px;
    background: #414141;
    color: #FFF;
    box-shadow: 0px 3px 6px -3px rgba(23, 24, 24, 0.08), 0px 8px 20px -4px rgba(23, 24, 24, 0.12);
    width: 240px;

    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);

    span {
        svg {
            margin-top: -3px;
        }
    }
}