.alert {
    border-radius: 10px !important;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05), 0px 0px 2px 0px rgba(0, 0, 0, 0.15), 0px 8px 40px 6px rgba(0, 0, 0, 0.04) !important;
}

.alert-success {
    background-color: #E3FFE7 !important;
}

.alert-info {
    background-color: #E3F0FF !important;
}