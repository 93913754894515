$sizes: 0, 4, 5, 8, 10, 12, 15, 16, 20, 24, 30, 32, 35, 40, 48, 60, 64, 80, 92, 96, 100, 120, 140, 160, 200;
$breakpoints: (
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
);

@each $size in $sizes {

    // paddings
    .c-p-#{$size} {
        padding: #{$size}px;
    }

    .c-pt-#{$size} {
        padding-top: #{$size}px;
    }

    .c-pb-#{$size} {
        padding-bottom: #{$size}px;
    }

    .c-ps-#{$size} {
        padding-left: #{$size}px;
    }

    .c-pe-#{$size} {
        padding-right: #{$size}px;
    }

    .c-py-#{$size} {
        padding-top: #{$size}px;
        padding-bottom: #{$size}px;
    }

    .c-px-#{$size} {
        padding-left: #{$size}px;
        padding-right: #{$size}px;
    }

    // responsive variants
    @each $bp, $w in $breakpoints {
        @media (min-width: #{$w}px) {
            .c-p-#{$bp}-#{$size} {
                padding: #{$size}px !important;
            }

            .c-pt-#{$bp}-#{$size} {
                padding-top: #{$size}px !important;
            }

            .c-pb-#{$bp}-#{$size} {
                padding-bottom: #{$size}px !important;
            }

            .c-ps-#{$bp}-#{$size} {
                padding-left: #{$size}px !important;
            }

            .c-pe-#{$bp}-#{$size} {
                padding-right: #{$size}px !important;
            }

            .c-py-#{$bp}-#{$size} {
                padding-top: #{$size}px !important;
                padding-bottom: #{$size}px !important;
            }

            .c-px-#{$bp}-#{$size} {
                padding-left: #{$size}px !important;
                padding-right: #{$size}px !important;
            }
        }
    }

    // margins
    .c-m-#{$size} {
        margin: #{$size}px;
    }

    .c-mt-#{$size} {
        margin-top: #{$size}px;
    }

    .c-mb-#{$size} {
        margin-bottom: #{$size}px;
    }

    .c-ms-#{$size} {
        margin-left: #{$size}px;
    }

    .c-me-#{$size} {
        margin-right: #{$size}px;
    }

    .c-my-#{$size} {
        margin-top: #{$size}px;
        margin-bottom: #{$size}px;
    }

    .c-mx-#{$size} {
        margin-left: #{$size}px;
        margin-right: #{$size}px;
    }

    // responsive variants
    @each $bp, $w in $breakpoints {
        @media (min-width: #{$w}px) {
            .c-m-#{$bp}-#{$size} {
                margin: #{$size}px !important;
            }

            .c-mt-#{$bp}-#{$size} {
                margin-top: #{$size}px !important;
            }

            .c-mb-#{$bp}-#{$size} {
                margin-bottom: #{$size}px !important;
            }

            .c-ms-#{$bp}-#{$size} {
                margin-left: #{$size}px !important;
            }

            .c-me-#{$bp}-#{$size} {
                margin-right: #{$size}px !important;
            }

            .c-my-#{$bp}-#{$size} {
                margin-top: #{$size}px !important;
                margin-bottom: #{$size}px !important;
            }

            .c-mx-#{$bp}-#{$size} {
                margin-left: #{$size}px !important;
                margin-right: #{$size}px !important;
            }
        }
    }

    // flex gap
    .c-gap-#{$size} {
        gap: #{$size}px;
    }

    .c-gap-row-#{$size} {
        row-gap: #{$size}px;
    }

    .c-gap-column-#{$size} {
        column-gap: #{$size}px;
    }
}