/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "./styles/alerts";
@import "./styles/animate.scss";
@import "./styles/buttons.scss";
@import "./styles/images.scss";
@import "./styles/fonts.scss";
@import "./styles/auth0";
// @import "./styles/scrollbars";
@import "./styles/typography";
@import "./styles/grid";
@import "./styles/modal";
@import "./styles/spacings";
@import "./styles/colors";
@import "./styles/modal";

// define our colors
$crew-pink: #FFA8CB;
$black: #111111;
$grey: #9E9E9E;
$light-gray: #EBEBEB;

body {
    --crew-pink: #{$crew-pink};
    --black: #{$black};
    --grey: #{$grey};
    --light-gray: #{$light-gray};

    // override bootstrap variables
    --bs-black: #{$black};
    --bs-pink: #{$crew-pink};
    --bs-gray: #{$grey};

    --bs-highlight-bg: #{$crew-pink};
}


body {
    // background-color: #161616;
    --bs-body-bg: #fff;
    // --bs-body-bg: #FFF3F3;
    --bs-font-family-sans-serif: 'The Future', sans-serif;
    --bs-body-font-family: 'The Future', sans-serif;

    --header-height: 80px;

    &.hide-header-logo {
        header {
            .logo {
                opacity: 0;
            }
        }
    }

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;

    overflow-x: hidden;
}

//overwrite bootstrap column paddings
.row>* {
    padding-left: 20px;
    padding-right: 20px;
}

input[type="text"],
input.form-control {
    padding: 8px 12px;
}

//overwrite bold
.fw-bold {
    font-weight: 600 !important;
}

.mobile-offcanvas {
    --bs-offcanvas-height: min(80vh, fit-content(100%));

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    // add a small line to the top of the offcanvas

    &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 5px;
        background-color: #8d8d8d;
        border-radius: 5px;

        top: 10px;
        left: 50%;
        transform: translateX(-50%);

    }

}

.btn-orange {
    --bs-btn-color: #fff;
    --bs-btn-bg: #C6763D;
    --bs-btn-border-color: #C6763D;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #c6763dcf;
    --bs-btn-hover-border-color: #C6763D;
    --bs-btn-focus-shadow-rgb: 198, 118, 61;
}

.substract-header {
    margin-top: calc(-1 * var(--header-height));
}

.font-rhetorik {
    font-family: 'Rhetorik Sans', sans-serif !important;
    font-weight: 400;
    font-style: normal;
}

// p {
//     @extend .font-rhetorik;
// }


a {
    &.no-decoration {
        --bs-link-color: none;
        --bs-link-hover-color: none;
        text-decoration: none;
    }
}

.text-bg-light {
    background-color: white !important;
}

.card {
    --bs-card-border-radius: 20px !important;

    &.shadow-sm {
        border: none !important;
    }

    &.shadow {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
        border: none !important;
    }
}


.modal-dialog-scrollable .modal-content {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

// .modal-xl {
//     --bs-modal-border-radius: 20px;
//     --bs-modal-width: 80vw !important;
//     --bs-modal-bg: transparent !important;

//     @media (max-width: 768px) {
//         --bs-modal-width: 100vw !important;
//         margin: 0 !important;
//         margin-top: 3vh !important;
//     }


//     .modal-content {
//         position: relative;
//         max-width: fit-content;
//         margin: auto;
//     }

//     .btn-close {
//         position: absolute;
//         right: 0px;
//         margin: 10px;
//         border-radius: 50px;
//         width: 40px;
//         height: 40px;
//         z-index: 9999;

//         // opacity: .5;
//         // font-size: 25px;

//         span {
//             height: 105%;
//         }

//         // transition: all .2s ease;

//         // &:hover {
//         //     opacity: 1;
//         // }

//     }

// }

.modal-header {
    border-bottom: none;

    button:focus {
        box-shadow: none;
        opacity: 0.5;
    }
}

.modal-backdrop {
    --bs-backdrop-opacity: 0.8;
}

.modal-backdrop-low-opacity {
    @extend .modal-backdrop;
    --bs-backdrop-opacity: 0.1;
}

.reader-modal-dialog {
    min-height: calc(100% - var(--bs-modal-margin)* 2);

    .modal-content {
        min-height: 100%;
    }
}

.reader-media {
    position: relative;
    width: calc(var(--bs-modal-width) - var(--scrollbar-width));
    left: 50%;
    transform: translateX(-50%);
    max-height: 70vh;
}

@media (max-width: 575px) {
    .reader-media {
        width: calc(100vw - 0.3rem);
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        transform: none;
    }
}

.reader-media-small {
    --video-border-radius: calc(0.5rem - 1px);
    position: relative;
    width: auto;
    height: 70vh;
    left: 50%;
    transform: translateX(-50%);
    border-radius: var(--video-border-radius);
}

@media (max-width: 575px) {
    .reader-media-small {
        width: auto;
        transform: translateX(-50%);
        height: 70vh;
        left: 50%;
    }
}

.media-modal-dialog {
    .modal-content {
        min-height: 600px;
    }
}


// @supports (mask-image: paint(smooth-corners)) {
//     .card {
//         border-radius: 0 !important;
//         mask-image: paint(smooth-corners) !important;
//         --smooth-corners: 10 !important;
//     }
// }



.badge.rounded-pill {
    padding: 10px 20px;
    border: 1px solid black;

    a {
        text-decoration: none;
        color: black;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


// Bootstrap overrides
// .placeholder-glow {
//     opacity: .5;
//     .placeholder {
//         border-radius: 5px;
//     }
// }
// .placeholder-glow {
//     opacity: .5;
//     .placeholder {
//         border-radius: 5px;
//     }
// }

.pointy {
    cursor: pointer !important;
}

.custom-file-input {
    visibility: hidden;
    position: absolute;
    position: absolute;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.tiktok-embed {
    margin: 0 !important;
}


// customise our badges
.badge {
    color: var(--Black, #111);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 8px 16px;
    border-radius: 80px;
    border: 0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.15), 0 1px 2.5px rgba(0, 0, 0, 0.05);
    text-decoration: none !important;
    user-select: none;

    transition: all .2s ease;
    cursor: pointer;

    @extend .d-flex;
    @extend .align-items-center;
    @extend .slight-hover;
    width: fit-content;

    a {
        color: var(--black, #000);
        text-decoration: none;
    }
}

.badge-with-image {
    padding: 7px 14px;
    @extend .badge;
}

.inactive-badge {
    color: var(--Black, #111);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 8px 16px;
    border-radius: 80px;
    border: 0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.15), 0 1px 2.5px rgba(0, 0, 0, 0.05);
    text-decoration: none !important;
    user-select: none;
    cursor: text !important;

    text-wrap: nowrap;

    @extend .d-flex;
    @extend .align-items-center;
    width: fit-content;

    &:hover {
        transform: translateY(0) !important;
    }

    display: block;
}

.inactive-badge-with-image {
    padding: 7px 14px;
    @extend .inactive-badge;
}

.slight-hover {
    transition: all .2s ease;
    cursor: pointer;

    &:hover {
        //slightly lift up the badge and darken the bg
        transform: translateY(-1px) !important;
        background-color: rgba(0, 0, 0, 0.05);
    }

    &.inactive-badge {
        transform: translateY(0) !important;
    }
}

.slight-hover-no-bg {
    transition: all .2s ease;
    cursor: pointer;

    &:hover {
        transform: translateY(-1px) !important;
    }

    &.inactive-badge {
        transform: translateY(0) !important;
    }
}

.animated-collection {
    >* {
        @extend .animated;
        @extend .fadeInUp;

        $delay: 0.01s;

        @for $i from 1 through 100 {
            &:nth-child(#{$i}) {
                animation-delay: calc(#{$delay} * (#{$i} - 1));
            }
        }
    }

}

.badge-collection {
    @extend .d-flex;
    @extend .flex-wrap;
    // @extend .py-2;
    // @extend .px-1;
    @extend .animated-collection;

    gap: 10px;

    &.horizontal {
        flex-wrap: nowrap !important;
        overflow-x: auto;
        overflow-y: visible;
    }

    .inactive-badge-with-image {
        margin-bottom: 2px;
        margin-top: 2px;
    }
}


.container-center {
    max-width: 800px;
    margin: auto;
}

.subtitle {
    color: var(--bs-gray);
}

.cover-container {
    // background: black;
    overflow: hidden;
    color: black;
    // Before pseudoelement

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-image: var(--cover-image);
        background-size: cover;

        background-position: center;
        filter: blur(40px) brightness(0.5);
        transform: scale(1.2);
        // z-index: -1;
    }

    .content {
        z-index: 10;
    }

}

.cursor-pointer {
    cursor: pointer !important;
}

.circular-pfp {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    // width: 100%;
    // height: 100%;
    object-fit: cover;
}

h1 {
    font-weight: 600 !important;
}

h2 {
    font-weight: 500 !important;
}

.no-border {
    border-width: 0 !important;
}

.no-resize {
    resize: none !important;
}

.no-select {
    user-select: none;
}

.rounded-input {
    border-radius: 25px;
}

.primary-input,
.input-primary {
    border: 1px solid #D9D9D9;
    transition: border 0.3s ease;

    // &:hover,
    &:active,
    &:focus {
        border: 1px solid #F69CBD;
        outline: 0;
        box-shadow: none;
    }
}

.dark-input,
.input-dark {
    border: 1px solid #D9D9D9;
    transition: border 0.3s ease;

    // &:hover,
    &:active,
    &:focus {
        border: 1px solid #000;
        outline: 0;
        box-shadow: none;
    }
}

.input-large {
    height: 48px;
}

.w-fit {
    width: fit-content;
}

.hidden {
    visibility: hidden;
    position: absolute;
}

.animated-visibility {
    opacity: 0;
    transition: opacity 0.8s linear;

    &.visible {
        opacity: 1;
        visibility: visible;
    }

    &.hidden {
        opacity: 0;
        visibility: hidden;
    }
}

.worldmaker-section-title {
    color: #121212 !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

.book-header-cta-controls {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    form {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        width: 100%;
    }

    .form-errors {
        width: 100%;
    }

    .input-container,
    form.input-container {
        width: 350px;

        input {
            width: 350px;
        }
    }

    .button-container,
    form.button-container {
        width: fit-content;

        .btn {
            width: fit-content;
        }
    }

    @media (max-width: 768px) {

        .input-container,
        form.input-container {
            width: 100%;

            input {
                width: 100%;
            }
        }

        .button-container,
        form.button-container {
            width: 100%;

            .btn {
                width: 100%;
            }
        }
    }
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}

.breadcrumb-container {
    --bg-color: #DDD;
    background-color: var(--bg-color);
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media (max-width: 1400px) {
        .container-xxl {
            .row {
                .col-12 {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
        }
    }
}

.breadcrumb-container::-webkit-scrollbar {
    display: none;
}

.horizontal-collection {
    margin-left: 12px;
    margin-right: 12px;
    padding-left: 0;
    padding-right: 0;
    column-gap: 15px;
    flex-wrap: nowrap;
    overflow-x: auto;

    //add some space before the scrollbar
    // padding-bottom: 15px;
    //hide scrollbar on mobile
    @media (max-width: 767px) {
        scrollbar-width: none;
        -ms-overflow-style: none;
        padding-bottom: 0;
    }
}

//hide scrollbar on mobile (safari)
@media (max-width: 767px) {
    .horizontal-collection::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none;
        width: 0;
        height: 0;
    }
}

.horizontal-collection>* {
    padding-left: 0;
    padding-right: 0;
}

.horizontal-collection.no-responsive>* {
    width: fit-content;
}

.vertical-collection {
    margin-bottom: -15px;
    padding-left: 12.5px;
    padding-right: 12.5px;
}

.vertical-collection>* {
    padding-bottom: 15px;
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.full-width-hr {
    width: 200vw;
    margin-left: -50%;
    opacity: 0.10;
}

//the big page headers, in Explore, world hub, author hub etc
.big-header {
    color: #121212 !important;
    font-size: 56px !important;
    font-style: normal !important;
    font-weight: 900 !important;
    line-height: normal !important;
}

@media (max-width: 575px) {
    .big-header {
        font-size: 32px !important;
    }
}

//standard gap between worldmaker sections
.worldmaker-section-container {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.pagination {
    --bs-link-hover-color: #F69CBD;
    --bs-link-color: #F69CBD;

    --bs-pagination-padding-x: 30px;
    --bs-pagination-padding-y: 10px;
    --bs-pagination-font-size: 16px;

    --bs-pagination-color: var(--bs-link-color);
    --bs-pagination-bg: #fff;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: #F69CBD;
    --bs-pagination-border-radius: 60px;

    --bs-pagination-hover-color: #FFF;
    --bs-pagination-hover-bg: #fbb6d2;
    --bs-pagination-hover-border-color: #fbb6d2;

    --bs-pagination-focus-color: #FFF;
    --bs-pagination-focus-bg: #fbb6d2;
    --bs-pagination-focus-box-shadow: none;

    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #F69CBD;
    --bs-pagination-active-border-color: #F69CBD;

    --bs-pagination-disabled-color: #808080;
    --bs-pagination-disabled-bg: #fff;
    --bs-pagination-disabled-border-color: #dee2e6;

    user-select: none;
}

.email-correction {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}


//loading this here globally so it can persist when route changes
//TODO change it if you find a better way
//this happens on /bonus. We open the reader/character modal and in the background we navigate to the book page.
//when the navigation finishes the modal styles are unloaded.
//Loading them globally here prevents that 
@import "./app/modules/readables/components/reader-popup-v2/reader-popup-v2.component.scss";
@import "./app/modules/books/components//books/character-overviews/character-overviews.component.scss";