.character-modal-body {
    overflow-x: hidden;
}

.character-modal-header {
    max-height: 72px;
    background: #FDEBF2;

    div {
        .character-modal-title {
            .black {
                color: #121212;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.14px;
            }

            .grey {
                color: #414141;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.14px;
            }

            .truncate {
                width: calc(0.45 * var(--bs-modal-width));
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.character {
    .header {
        .name {
            color: #121212;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.2px;
        }

        .icon {
            margin-left: 10px;
        }
    }

    .appearance {
        color: #414141;
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.16px;
    }

    .description {
        .title {
            color: #121212;
            font-weight: 500;
            font-size: 18px;
            letter-spacing: -0.18px;
        }
    }

    .did-you-know {
        .title {
            color: #121212;
            font-weight: 500;
            font-size: 18px;
            letter-spacing: -0.18px;
        }
    }
}

.extras-badge {
    display: inline-flex;
    width: fit-content;
    height: 23px;
    padding: 0px 8px;
    align-items: center;
    gap: 2px;
    flex-shrink: 0;
    border-radius: 100px;
    border: 1px solid #FF9800;
    background: #FFF3E0;

    .text {
        color: #FF9800;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: -2px;
    }

    .icon {
        svg {
            margin-top: -6px;
        }
    }
}

.extras-badge.white-bg {
    background: #FFF3E0;
}

.character-overviews-title {
    color: #121212;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media (max-width: 768px) {
    .character-overviews-title {
        font-size: 24px;
    }
}

.bonus-scene-unlock-toast {
    width: 300px;
}

.fade-text {
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 100%);
    user-select: none;
}