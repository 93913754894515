.body {
    // modify bootstrap variables
    // h1


}

h1 {
    font-weight: 900;
}
