.auth0-lock-badge-bottom {
    display: none !important;
}

.auth0-lock-header-logo {
    // display: none !important;
}

.auth0-lock-header-bg {
    background: #fff !important;
}

.auth0-lock-social-button.auth0-lock-social-big-button {
    border-radius: 38px !important;
}

.auth0-lock-name {
    display: none !important;
}