body {
    --bs-border-radius-pill: 16px;
}

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: fit-content;

    font-weight: 500;
    box-shadow: 0px 8px 40px 6px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.15), 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
    padding: 8px 32px;
    height: 42px;
}

//full width on mobile
@media (max-width: 768px) {
    .btn {
        width: 100%;
    }
}

.btn-large {
    height: 48px;
}

.btn-small {
    height: 35px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 8px 15px !important;

    //very small screens
    @media (max-width: 412px) {
        font-size: 12px !important;
        padding: 6px 12px !important;
    }
}

.btn-primary {
    --bs-btn-color: #121212;
    --bs-btn-bg: #000;
    --bs-btn-border-color: #000;
    --bs-btn-hover-color: #121212;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #333;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #121212 !important;
    --bs-btn-active-bg: #333;
    --bs-btn-active-border-color: #transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #B3B3B3;
    --bs-btn-disabled-bg: #F69CBD;
    --bs-btn-disabled-border-color: #F69CBD;

    // FFA8CB
    --bs-btn-bg: #F69CBD;
    --bs-btn-color: #121212;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #121212;
    --bs-btn-hover-bg: #fbb6d2;
    --bs-btn-hover-border-color: transparent;

    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #121212;
    --bs-btn-active-bg: #fbb6d2;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #B3B3B3;
    --bs-btn-disabled-bg: #F69CBD;
    --bs-btn-disabled-border-color: #F69CBD;



    // make it pill-shaped
    // --bs-btn-border-radius: 50rem;
    // --bs-btn-border-radius-lg: 50rem;
    // --bs-btn-border-radius-sm: 50rem;
}

.btn-secondary {
    --bs-btn-color: #000000;
    --bs-btn-bg: #fff;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #ececec;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;

    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #d7d7d7;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
}

.btn-outline-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: #fff;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #fff;
    --bs-btn-focus-shadow-rgb: 255, 255, 255;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #fff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #fff;
}

.btn-outline-secondary {
    --bs-btn-color: #000;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: #000;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #000;
    --bs-btn-focus-shadow-rgb: 0, 0, 0;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #000;
    --bs-btn-active-border-color: #000;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #000;

    --bs-btn-text-color: #000;

    // no shadow
    box-shadow: none;
}


.btn-round {
    --bs-btn-border-radius: 50rem;
    --bs-btn-border-radius-lg: 50rem;
    --bs-btn-border-radius-sm: 50rem;
}

.btn-dark {

    --bs-btn-color: #fff;
    --bs-btn-bg: #121212;
    --bs-btn-border-color: #121212;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #27292b;
    --bs-btn-hover-border-color: #1e2021;
    --bs-btn-focus-shadow-rgb: 66, 70, 73;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #282a2c;
    --bs-btn-active-border-color: #17181a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #121212;
    --bs-btn-disabled-border-color: #121212;
}


.btn-icon {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
}

.btn-cta {
    padding: 8px 18px;
    color: #121212;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 100px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    box-shadow: none !important;
    transition: border 0.3s ease;

    &:hover,
    &:active,
    &:focus {
        border: 1px solid rgba(0, 0, 0, 1);
    }
}

.btn-with-icon {
    feather-icon {
        margin-right: 10px;
    }

    feather-icon[icon="user-plus"],
    feather-icon[icon="user-check"] {
        margin-bottom: 2px;
    }

    feather-icon[icon="book"] {
        margin-bottom: 4px;
    }

    feather-icon[icon="download"] {
        margin-bottom: 5px;
    }

    svg.amazon {
        margin-top: 4px;
        margin-right: 10px;
    }
}