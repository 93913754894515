.col-25 {
    width: 20% !important;
}

.col-sm-25 {
    @media (min-width: 576px) {
        width: 20% !important;
    }
}

.col-md-25 {
    @media (min-width: 768px) {
        width: 20% !important;
    }
}

.col-lg-25 {
    @media (min-width: 992px) {
        width: 20% !important;
    }
}

.col-xl-25 {
    @media (min-width: 1200px) {
        width: 20% !important;
    }
}

.col-xxl-25 {
    @media (min-width: 1400px) {
        width: 20% !important;
    }
}
